.Analytics {
	flex-grow: 1;
	padding: 40px 0;
	width: 100%;
	min-height: calc(100vh - 80px - 80px);
	position: relative;

	h2 {
    margin-bottom: 16px;
  }


	.analytics-wrap {
		margin-bottom: 24px;
	}

	.Select-hidden {
		padding-bottom: 32px;
		.select-wrapper {
			margin: 0 auto;
		}
	}
}	

@media (max-width: 500px) {
	.Analytics {
		h2 {
			font-size: 20px;
			text-align: left;
		}

		.Select-hidden {
			right: 15px;
		}
	}
}