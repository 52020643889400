.navbar {
  background-color: #160f09;
  width: 100%;
  height: 70px;
}

.navbar-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.navbar .navbar-wrap,
.navbar .navbar-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  .logo-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%; 
		.mama-logo {
			width: 60px;
		}
    a {
      margin-right: 11px;
    }
  }
}


.link {
  font-family: Gotham;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.01em;
  color: #ffffff;
  text-decoration: none;
}

.mama-logo {
  width: 120px;
	margin-left: 10px;
}

.toggle-btn {
  width: 160px;
  display: flex;
  justify-content: flex-end;
}

.hamburger-react {
  div {
    background-color: #ffffff !important;
  }
}




