.Summary {
  padding-top: 6vh;
	padding-bottom: 10px;
  text-align: center;
	flex-grow: 1;
	display: flex;
	align-items: center;
	flex-direction: column;





	.summary__top__wrapper {
		display: flex;
		justify-content: space-between;
		width: 100%;
		.summary__top-select {
			width: 60%;
		}

		.summary__top-logo {
			display: flex;
			align-items: center;
			width: 100%;
			justify-content: center;			
		}
	}

	.summary__top__wrapper .summary__top-select+.summary__top-logo {
		width: 30% !important;
	}

	.summary__top__wrapper:not(.multiple) {
		width: 100% !important;	
	}

	
  .invitation {
    margin-bottom: 16px;
  }

  .logo {
		width: 85px; 
		margin-bottom: 11px;
  }



}




