.summary__top__wrapper {
  margin-bottom: 40px;
}

.SummaryItem {
  margin-bottom: 16px;
  h2 {
    margin-bottom: 8px;
  }
  .data {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 4px;
  }
  .comment {
    font-size: 16px;
    white-space: pre-line;
		margin-bottom: 0px;
  }
}


@media (max-height: 750px) {
  .summary__top__wrapper {
    margin-bottom: 16px;
  }

  .SummaryItem {
    h2 {
      font-size: 28px;
    }
    margin-bottom: 16px;
    .data {
      font-size: 32px;
      font-weight: 700;
      margin-bottom: 4px;
    }
    .comment {
      margin-bottom: 0;
    }    
  }

}


@media (max-height: 660px) {
  .summary__top__wrapper {
    margin-bottom: 0px;
  }

  .SummaryItem {
    h2 {
      font-size: 22px;
    }
    margin-bottom: 16px;
    .data {
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 4px;
    }
    .comment {
      margin-bottom: 0;
    }    
  }

}




@media (max-height: 660px) {
  .SummaryItem {
    h2 {
      font-size: 22px;
    }
    margin-bottom: 16px;
    .data {
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 4px;
    }
    .comment {
      margin-bottom: 0;
    }    
  }

}
