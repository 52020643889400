.Leads {
	flex-grow: 1;
	padding: 40px 0;
	width: 100%;
	min-height: calc(100vh - 80px - 80px);


  h2 {
    margin-bottom: 16px;
  }

    .leads-wrap.leads-dynamic {
      height: 40%;
      margin-bottom: 24px;
    }
    .leads-wrap.leads-forms {
      height: 60%;
    }

		.Select-hidden {
			padding-bottom: 32px;
			.select-wrapper {
				margin: 0 auto;
			}
		}

	.Tabs .tags {
		width: 261px;
	}	


}

@media (max-width: 500px) {
	.Leads {
		h2 {
			font-size: 20px;
			text-align: left;
		}

		.Select-hidden {
			right: 15px;
		}
	}
}
