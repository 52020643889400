.layout {
	height: 100vh;
	overflow: hidden;
	.container {
		max-width: 900px;
		margin: 0 auto;
		height: 100%;
		padding: 0 10px 0 10px;

	}
	.main {
		height: calc(100vh - 70px - 50px);
		position: relative;
		.container {
			height: calc(100vh - 70px - 50px);
			position: relative;
			display: flex;
			align-items: stretch;
		}	
	}
}


@media (max-height: 500px) {
	.layout {
		height: auto;
		overflow: auto;
		.main {
			height: auto;
			.container {
				height: auto;
			}
		}
	}
}

