.tags {
	display: flex;
	justify-content: space-between;
	margin: 0 auto;
	div {
		display: inline-block;
		padding: 8px 12px;
		cursor: pointer;
		font-weight: 500;
		border: 1px solid #d6d6d6;
		transition: background 0.25s ease-in-out;
		text-align: center;
		&.active {
			background-color: #d6d6d6;
		}
	}
}

.tabs_content {
	&>div {
		position: relative;
		top: -1px;
		z-index: 1;
		padding: 0px;
		margin-top: 16px;
		background-color: #fff;
		overflow-x: scroll;
		display: none;
		&.active {
			display: block;
		}
	}	
}