.TabContent {
  position: relative;
  top: -1px;
  z-index: 1;
  padding: 0px;
  margin-top: 16px;
  background-color: #fff;
  overflow-x: scroll;
  display: none;

  &.active {
    display: block;
		opacity: 1;
  }


  table {
    width: max-content;
    position: relative;
    max-height: 26vh;
    height: 100%;
    display: inline-block;
  }

  thead {

    background-color: #ffffff;
		td {
			background-color: #ffffff;
		}
  }

  tbody {
    overflow-y: scroll;
    max-height: 20vh;
  }

  th {
    padding-top: 6px;
    padding-bottom: 6px;
		background-color: #ffffff;
		position: relative;
		z-index: 1000;

  }

  th, td {
    padding: 4px 8px;
    border: 1px #d6d6d6 solid;
    text-align: left;
    max-width: 346px;
  }

  th {
    font-weight: 500;
  }

	tr {
		position: relative;
		&.green {
			background-color: rgb(219, 252, 238);
			&:after {
				content: 'new';
				color: rgb(5, 139, 81);
				position: absolute;
				font-weight: 700;
				font-size: 10px;
				top: -3px;
				left: 90px;
				z-index: 1;
			}
		}		
	}

  td {
    font-weight: 350;
  }
}