.Alert {
  display: block;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.9);
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  color: red;
  font-weight: 500;
  text-align: center;
  padding: 8px 20px;
  max-width: 90%;
  width: 800px;
}