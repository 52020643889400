.Input {
  margin-bottom: 16px;
}

.Input label {
  margin-bottom: 4px;
  padding: 0;
  display: block;
  font-weight: 500

}

.Input input {
  display: block;
  box-sizing: border-box;
  border: 1px solid #b8b8b8;
  padding: 8px;
  margin: 0 0 8px; 
  height: 40px;
  width: 100%;
  outline: none;
  transition: all 300ms ease-in-out;
  border-radius: 4px;
  &:focus {
    background-color: #fafafa;
  }
}

.Input span {
  color: #f01f30;
  font-size:12px;
  font-weight: bold;

}

.Input.invalid label {
  color: #f01f30;
}