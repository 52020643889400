button {
  width: 100%;
  height: 43px;
  background-color: #d9c7c7;
  color: #000;
  font-size: 18px;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 7px;
	transition: background 0.25s ease-in-out;



  &.disabled {
      // background-color: #e9e9e9;
      // cursor: default;
      // color: #b4b3b3;
			cursor: inherit;
  }
}  

@media (max-height: 800px) {
  button {
    width: 70%;
    height: 34px;
    font-size: 16px;
  }
}