.Auth {
  padding-top: 12%;
	flex-grow: 1;
	width: 100%;
	max-width: 323px;
	margin: 0 auto;

	button {
		&.disabled {
				background-color: #e9e9e9;
				cursor: default;
				color: #b4b3b3;
				cursor: inherit;
		}
	}  

  h1 {
    margin-bottom: 24px;
  }
}